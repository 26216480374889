// import { useContext } from "react";
import { merge } from "theme-ui"
import { tailwind } from "@theme-ui/presets"

const theme = merge(tailwind, {
  initialColorModeName: `dark`,
  useCustomProperties: true,
  breakpoints: [`400px`, `600px`, `900px`, `1200px`, `1600px`],
  //smallMobile,mobile,tablet,laptop,largeLaptop
  footer: {
    textAlign: `center`,
    display: `block`,
    position: `absolute`,
    bottom: 0,
    color: `textMuted`,
    px: [2, 3],
    py: [3, 4],
  },
  styles: {
  
    blockquote: {
      marginLeft: 0,
      p: {
        fontSize: [2, 3],
        fontWeight: `medium`,
        color: `heading`,
      },
    },

    h2: {
      fontSize: [4, 7, 8],
      mt: 2,
      mb: 2,
      color: `heading`,
    },
    h3: {
      fontSize: [3, 6, `5rem`],
      color: `heading`,
      fontWeight: `100`,
      fontStyle: `italic`,
      m: `0`,
    },
    h4: {
      fontSize: [0, 1, 2],
      color: `heading`,
    },
    h5: {
      fontSize: [1, 2, 3],
      color: `heading`,
    },
    h6: {
      fontSize: 1,
      mb: 2,
      color: `heading`,
    },
  },
  layout: {
    container: {
      maxWidth: `5xl`,
    },
  },

  texts: {
    bigger: {
      p: {
        fontSize: [2, 3, 4],
      },
    },
  },
})

export default theme

export const alturahTheme = {
  colors: {
    merkadah: {
      yellow: "#FFB900",
      gold: "#D98E04",
      orange: "#F25C05",
      red: "#D93232",
      black: "#0D0D0D",
      white: "#F2F9EF",
      magenta: "#FF00FF",
      // bone: "#E8EDE8",//text
      // darkGrey: "#323232", // BG color?? imactive fonts??
    },
  },
  typography: {
    fontSizes: [
      "0.875rem",
      "1rem",
      "1.25rem",
      "1.5rem",
      "1.875rem",
      "2.25rem",
      "3rem",
      "4rem",
      "4.5rem",
      "5rem",
      "5.5rem",
      "6rem",
      "10rem",
    ],
  },
  spacing: null,
}

export const shadow = {
  H1: `text-shadow: 1px 2px 5px ${alturahTheme.colors.merkadah.black};`,
  P1: `text-shadow: .5px .5px 1px ${alturahTheme.colors.merkadah.black};`,
  Button: `box-shadow: 2px 2px 10px ${alturahTheme.colors.merkadah.black};`,
  Hover: `box-shadow: 1px 1px 10px ${alturahTheme.colors.white};`,
}

const size = {
  mobileS: "400px",
  mobileL: "600px",
  tablet: "900px",
  laptop: "1200px",
  desktop: "1600px",
}
export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  desktop: `(max-width: ${size.desktop})`,
}
